import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { OnLoadPopUpService } from '../common-services/onloadpopup.service';
import { EventdispatchService } from '../common-modal/eventdispach.service';
import { Subscription } from 'rxjs';
import { PageDataState } from '../../state-management/state/main-state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-static-footer',
  template: ` <div class='ct-footer'>
  <p *ngIf="keywords!=undefined">{{keywords.dictionary.footer | FooterPipe : year }} </p>
  <p style='margin-top:-10px;'>CareerTrek® is a registered trademark of the University of Oregon</p>
</div>`
})

export class StaticFooterComponent implements OnInit {

  pagesText: any;
  languagekey = 'en';
  lang: string;
  subscription: Subscription;
  year: number;
  keywords: any;
  constructor(public eventService: EventdispatchService, private oninitpopupservice: OnLoadPopUpService,
    private store: Store<PageDataState>, ) {
    this.subscription = eventService.listen().subscribe((e) => {
      if (e.type === 'languageChanged') {
        this.ngOnInit();
      }
    });
    this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
  }
  ngOnInit() {
    this.year = (new Date()).getFullYear();
    this.pagesText = this.store.select('pages').subscribe((resp) => {
      if (resp.pages != undefined && resp.pages[0] != null) {
        const data = (resp.pages);
        for (let i = 0; i < data.length; i++) {
          if (data[i].pageCode == 'GLOBAL_SETTINGS') {
            this.keywords = data[i];
          }
        }
      }
    });
  }
}
